var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { padding: "0 0 32px 32px" } },
    [
      _c("h4", { style: { marginBottom: "20px" } }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "v-chart",
        {
          attrs: {
            data: _vm.data,
            height: _vm.height,
            "force-fit": true,
            scale: _vm.scale,
            onClick: _vm.handleClick,
          },
        },
        [
          _c("v-tooltip"),
          _c("v-axis"),
          _c("v-legend"),
          _c("v-bar", {
            attrs: { position: "x*y", color: "type", adjust: _vm.adjust },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }